const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/api/';
}

export default {
    auth: {
        login: baseUrl + 'auth/login/',
        status: baseUrl + 'auth/status/'
    },

    projects: {
        get: baseUrl + 'projects/get/',
        list: baseUrl + 'projects/list/',
        create: baseUrl + 'projects/create/',
        dynamic: baseUrl + 'projects/dynamic/',
        suggestName: baseUrl + 'projects/suggest-name/',

        saveDocument: baseUrl + 'projects/save-document/',

        requirementSuggestion: baseUrl + 'projects/requirement-suggestion/',
        functionalitySanitizer: baseUrl + 'projects/functionality-sanitizer/'
    },

    master: {
        createPrompt: baseUrl + 'master/prompts/create/',
        listPrompt: baseUrl + 'master/prompts/list/',
        updatePrompt: baseUrl + 'master/prompts/update/',

        createPersona: baseUrl + 'master/personas/create/',
        listPersona: baseUrl + 'master/personas/list/',
        updatePersona: baseUrl + 'master/personas/update/',

        createTonality: baseUrl + 'master/tonalities/create/',
        listTonality: baseUrl + 'master/tonalities/list/',
        updateTonality: baseUrl + 'master/tonalities/update/'
    },

    bots: {
        listChatBots: baseUrl + 'chat-bots/list/',
        listBots: baseUrl + 'bots/list/',
        createBots: baseUrl + 'bots/create/',
        updateBots: baseUrl + 'bots/edit/',
        performBots: baseUrl + 'bots/perform/',
        promptBots: baseUrl + 'bots/prompt/'
    }
};
