const Layout = () => import(/* webpackChunkName: "login" */'../layouts/BlankLayout');

export default {
    path: '/',
    name: 'AuthLayout',
    redirect: '/login/',
    component: Layout,
    children: [
        // Routers for local testing start
        {
            path: '/login/',
            name: 'Login',
            component: () => import(/* webpackChunkName: "login" */'../views/auth/LoginPage')
        }
    ]
};
