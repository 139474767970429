<template>
    <div>
        <h1>Create Bot</h1>
        <s-form class="row" @submit="saveBot">
            <div class="col-md-12">
                <validated-input label="Name" v-model="model.name"/>
            </div>
            <div class="col-md-12">
                <markdown-editor v-model="model.description" label="Description"/>
            </div>
            <div class="col-md-12">
                <markdown-editor v-model="model.prompt" label="Prompt"/>
            </div>
            <div class="col-md-12">
                <btn text="Create Bot" color="success" icon="fa fa-plus" @click="saveBot"/>
            </div>
        </s-form>
    </div>
</template>

<script>
import MarkdownEditor from '@components/forms/markdown-editor.vue';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'CreateBot',
    components: { MarkdownEditor },
    data () {
        return {
            model: {
                name: '',
                description: '',
                prompt: ''
            }
        };
    },
    computed () {

    },
    methods: {
        async saveBot () {
            try {
                const response = await axios.form(urls.bots.createBots, {
                    ...this.model
                });
                console.log(response.data);
            } catch (error) {
                console.error(error);
            }
        }
    }
};
</script>
