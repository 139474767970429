<template>
    <div class="fl-y full-height">
        <top-bar description="Projects">
            <div class="btn-group" v-if="!!requirements">
                <btn size="" text="Generate Requirement Suggestions" @click="getSuggestions" color="primary" icon="fa fa-search"/>
                <btn size="" text="Create Project" @click="createProject" color="primary" icon="fa fa-save"/>
            </div>
        </top-bar>

        <div class="fl-gr-1 fl-x">
            <div class="row w-100p ml-3 mt-3">
                <div class="fl-gr-3 col">
                    <div class="row">
                        <div class="col-md-6">
                            <validated-input label="Project Name" v-model="projectName">
                                <template #append>
                                    <btn size="sm" text="Suggest a Name" @click="suggestAName" color="primary" icon="fa fa-search"/>
                                </template>
                            </validated-input>
                        </div>
                        <div class="col-md-6">
                            <validated-input label="Client Name" v-model="clientName"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <markdown-editor v-model="requirements" textarea-classes="min-h-20r"/>
                        </div>
                    </div>
                </div>
                <div class="p-2 fl-gr-1 col">
                    <div v-if="suggestions" class="bl-1 px-4">
                        <div v-for="(suggestion, index) in suggestions" :key="suggestion" class="mb-3 pr-2">
                            <div>
                                <span v-html="suggestion"></span>
                            </div>
                            <div class="btn-group">
                                <btn size="sm" text="Include" @click="addSuggestion(suggestion, index)" color="success" icon="fa fa-plus"/>
                                <btn size="sm" text="Exclude" @click="notRequired(suggestion, index)" color="danger" icon="fa fa-minus"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TopBar from '@components/TopBar.vue';
import urls from '@/data/urls';
import axios from 'secure-axios';
import MarkdownEditor from '@components/forms/markdown-editor.vue';

export default {
    components: {
        MarkdownEditor,
        TopBar
    },
    data () {
        return {
            projectName: '',
            clientName: '',
            project: null,
            requirements: '',
            suggestions: '',
            section: '',
            sanitized: ''
        };
    },
    created () {

    },
    methods: {
        async getProject () {
            const projectId = this.$route.params.id;
            const data = { id: projectId };
            const response = await axios.post(urls.projects.get, data);
            this.project = response.data;
        },

        async getSuggestions () {
            const requirements = this.requirements;
            if (requirements) {
                const data = {
                    requirements: requirements,
                    section: this.section
                };

                this.sanitized = '';

                const url = urls.projects.requirementSuggestion;
                const response = await axios.form(url, data);
                const suggestions = response.data;
                const lines = suggestions.split('\n');
                let line;
                const points = [];
                for (let i = 0; i < lines.length; i++) {
                    line = lines[i];
                    if (line.startsWith('* ')) {
                        const sanitized = line.substring(1).trim();
                        points.push(sanitized);
                    }
                }
                this.suggestions = points;
            }
        },

        async createProject () {
            const requirements = this.requirements;
            if (requirements) {
                const data = {
                    name: this.projectName,
                    client: this.clientName,
                    requirements: requirements
                };

                const url = urls.projects.create;
                const response = await axios.form(url, data);
                console.log(response.data);
            }
        },

        async suggestAName () {
            const requirements = this.requirements;
            if (requirements) {
                const data = {
                    prompt: requirements
                };

                const url = urls.projects.suggestName;
                const response = await axios.form(url, data);
                console.log(response.data);
                this.projectName = response.data;
            }
        },

        addSuggestion (suggestion, index) {
            this.requirements += '\n\n' + suggestion;
            this.suggestions.splice(index, 1);
        },

        notRequired (suggestion, index) {
            this.requirements += '\n\n' + suggestion + ' is not required';
            this.suggestions.splice(index, 1);
        }
    }
};
</script>
