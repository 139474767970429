<template>
    <div class="card bg-1 container">
        <div class="fl-te-c pb-2 bb-2 mb-3 b-primary">
            <h1>Requirement Generator</h1>
            <div class="btn-group">
                <btn size="sm" text="Generate" loading-text="Loading" :loading="generatingRequirements" @click="generateRequirements"/>
                <btn size="sm" text="Enhance" loading-text="Loading" :loading="enhancingRequirements" @click="enhanceRequirements"/>
                <btn size="sm" text="Spring Boot" loading-text="Loading" :loading="generatingSpringComponents" @click="generateSpringComponents"/>
                <btn size="sm" text="Process Document" loading-text="Loading" :loading="generatingProcess" @click="listProcess"/>
            </div>
        </div>
        <markdown-editor label="Enter the Basic Requirements" v-model="requirements"/>
        <markdown-editor class="mt-4" label="The Generated Requirements" v-model="generatedRequirements"/>
        <markdown-editor class="mt-4" label="The Enhanced Requirements" v-model="enhancedRequirements"/>
        <markdown-editor class="mt-4" label="Process Document" v-model="processDocument"/>
        <markdown-editor class="mt-4" label="Spring Components" v-model="sprintComponents"/>
    </div>
</template>

<script>
import axios from 'secure-axios';
import MarkdownEditor from '@components/forms/markdown-editor.vue';

const headers = { 'Content-Type': 'text/plain' };
const config = { headers: headers };

const baseURL = '';
const generateRequirements = baseURL + '/requirement-generator/';
const enhanceRequirements = baseURL + '/functional-architect/';
const springComponents = baseURL + '/spring-components/';
const listProcess = baseURL + '/list-process/';

export default {
    name: 'ToolInterface',
    components: { MarkdownEditor },
    data () {
        return {
            requirements: '',
            generatedRequirements: '',
            enhancedRequirements: '',
            processDocument: '',
            sprintComponents: '',

            generatingRequirements: false,
            generatingSpringComponents: false,
            generatingProcess: false,
            enhancingRequirements: false
        };
    },
    methods: {
        async generateRequirements () {
            this.generatingRequirements = true;
            const response = await axios.post(generateRequirements, this.requirements, config);
            this.generatedRequirements = response.data;
            this.generatingRequirements = false;
        },

        async enhanceRequirements () {
            this.enhancingRequirements = true;
            const response = await axios.post(enhanceRequirements, this.generatedRequirements, config);
            this.enhancedRequirements = response.data;
            this.enhancingRequirements = false;
        },

        async generateSpringComponents () {
            this.generatingSpringComponents = true;
            const response = await axios.post(springComponents, this.enhancedRequirements, config);
            this.sprintComponents = response.data;
            this.generatingSpringComponents = false;
        },

        async listProcess () {
            this.generatingProcess = true;
            const response = await axios.post(listProcess, this.enhancedRequirements, config);
            this.processDocument = response.data;
            this.generatingProcess = false;
        }
    }
};
</script>
