<template>
    <div>
        <transition name="fade" mode="out-in">
            <div v-if="selected === null" class="container bg-2 card">
                <h2 class="text-primary">Bots</h2>
                <div v-for="bot in bots" :key="bot.id" class="round-2 p-2 col-md-4 bg-1">
                    <b>{{ bot.name }}</b>
                    <p class="text-justify">{{ bot.description }}</p>
                    <btn icon="fa fa-pencil" text="Perform" size="sm" class="mr-2" @click="selectBot(bot)"/>
                </div>
            </div>
            <div v-else class="m-2">

                <div class="row">

                    <div class="col-md-6">
                        <div class="fl-te-c">
                            <h2 v-text="selected.name"></h2>
                            <div class="btn-group">
                                <btn text="Back" @click="selected=null"/>
                                <btn text="Do the AI Magic!!!" @click="perform" loading-text="Doing the AI Magic..." :loading="performing"/>
                            </div>
                        </div>

                        <markdown-editor v-model="prompt"/>
                    </div>

                    <div v-if="response" class="col-md-6">
                        <div class="fl-te-c mb-4">
                            <h2>AI Magic</h2>
                            <div class="btn-group">
                                <btn text="Copy Subject" @click="copySubject"/>
                                <btn text="Copy Message" @click="copyMessage"/>
                            </div>
                        </div>
                        <b v-html="response.subject"></b>
                        <div class="mt-4 text-justify">
                            <div v-html="response.message"></div>
                        </div>
                    </div>
                </div>

            </div>
        </transition>
    </div>
</template>

<script>
import axios from 'secure-axios';
import MarkdownEditor from '@components/forms/markdown-editor.vue';
import showdown from 'showdown';
import urls from '../../data/urls';

function copyToClipboard (text) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        return navigator.clipboard.writeText(text);
    } else {
        // Fallback for older browsers
        var textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }
}

const converter = new showdown.Converter();

function mmd (src) {
    return converter.makeHtml(src);
}

export default {
    name: 'ListBot',
    components: { MarkdownEditor },
    data () {
        return {
            bots: [],
            performing: false,
            selected: null,
            prompt: '',
            response: ''
        };
    },

    async mounted () {
        try {
            const response = await axios.get(urls.bots.listBots);
            this.bots = response.data.response;
            console.log(this.bots);
        } catch (error) {
            console.error(error);
        }
    },

    methods: {
        selectBot (bot) {
            this.selected = bot;
        },

        async perform () {
            if (this.performing) return;
            this.response = null;
            this.performing = true;
            const id = this.selected.id;
            const headers = { 'Content-Type': 'text/plain' };
            const config = { headers: headers };
            const response = await axios.post(urls.bots.performBots + id + '/', this.prompt, config);
            const message = response.data;
            const index = message.indexOf('\n');
            this.response = {
                subject: message.substring(0, index),
                message: mmd(message.substring(index + 1))
            };
            this.performing = false;
        },

        copySubject () {
            copyToClipboard(this.response.subject);
        },

        copyMessage () {
            copyToClipboard(this.response.message);
        }
    }
};
</script>

<style>
pre.response {
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>
