<template>
    <login-page-design1 url="url" @login-success="onSuccess" heading-text="Login">
    </login-page-design1>
</template>

<script>
import LoginPageDesign1 from 'lego-modules/src/pages/login/design-1';
import { mapActions } from 'vuex';

export default {
    name: 'LoginPage',
    components: { LoginPageDesign1 },
    methods: {
        ...mapActions(['setUser']),
        async onSuccess (json) {
            const username = json.user;
            console.log('username:', json, username);
            this.setUser(username);

            const currentRoute = this.$route.path;
            if (currentRoute !== '/') {
                await this.$router.push({ path: '/' });
            }
        }
    }
};
</script>
