<template>
    <div class="full-height fl-y">
        <top-bar :description="name">
            <div class="btn-group mr-3">
                <btn :text="saveButtonText" icon="fa fa-save" @click="saveButtonClicked" :loading="saving" loading-text="Saving..." />
                <btn text="New" icon="fa fa-plus" @click="newButtonClicked" :loading="saving" loading-text="Creating..." />
            </div>
        </top-bar>
        <div class="fl-x fl-gr-1">
            <div class="bg-3 w-20r">
                <side-bar-item :items="items" @select="selectItem" />
            </div>

            <div class="fl-gr-1 fl-y">
                <div v-if="loading">
                    <div class="bg-info card fl-x">
                        <loading-animation/>
                        <span class="ml-2">Loading the {{ name }}.</span>
                    </div>
                </div>

                <div class="container mt-4 fl-y" v-else-if="!selected">
                    <div class="bg-info card">Please select a {{name }}.</div>
                </div>

                <div v-else>
                    <markdown-editor v-model="content" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TopBar from '@components/TopBar.vue';
import MarkdownEditor from '@components/forms/markdown-editor.vue';
import axios from 'secure-axios';
import urls from '@/data/urls';
import SideBarItem from '@components/SideBarItem.vue';

export default {
    components: { SideBarItem, MarkdownEditor, TopBar },
    props: {

        urls: urls.master,

        name: { type: String, default: 'Prompt Editor' },
        saveButtonText: { type: String, default: 'Save' },

        newUrl: { type: String, required: true },
        listUrl: { type: String, required: true },
        saveUrl: { type: String, required: true }
    },

    data () {
        return {
            items: [],
            content: '',
            selected: null,
            loading: false,
            saving: false
        };
    },
    async mounted () { await this.loadData(); },
    methods: {
        async loadData () {
            try {
                this.items = [];
                const response = await axios.get(this.listUrl);
                this.items = response.data.response;
            } catch (error) {
                console.error(error);
            }
        },

        async saveButtonClicked () {
            if (this.saving) return;

            this.saving = true;
            const id = this.selected.id;
            const data = { id: id, content: this.content };
            const response = await axios.form(this.saveUrl, data);
            console.log(response.data);

            const items = this.items;
            const index = items.findIndex(item => item.id === id);
            items[index].content = this.content;

            this.saving = false;
        },

        async newButtonClicked () {
            const name = prompt('Enter the name ?');
            if (!name) return;
            const data = { name: name, content: '' };
            const response = await axios.form(this.newUrl, data);
            console.log(response.data);
            await this.loadData();
        },

        selectItem (item) {
            this.selected = item;
            this.content = item.content;
        }
    }
};
</script>
