<template>
    <editor name="Prompts" :save-url="urls.updatePrompt" :list-url="urls.listPrompt" :new-url="urls.createPrompt"/>
</template>
<script>
import Editor from '@/views/editor/Editor.vue';
import urls from '@/data/urls';

export default {
    components: { Editor },
    data () { return { urls: urls.master }; }
};
</script>
