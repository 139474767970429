<template>
    <div>
        <textarea ref="editor"></textarea>
    </div>
</template>

<script>
import EasyMDE from 'easymde';
import 'easymde/dist/easymde.min.css';

export default {
    name: 'MarkdownEditor',
    props: {
        value: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: ''
        },
        config: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        'value' (newValue) {
            if (this.model === newValue) {
                // console.log(newValue, this.model);
                return;
            }
            this.model = newValue;
            this.easyMDE.value(newValue);
        }
    },

    data () {
        return {
            easyMDE: null,
            model: ''
        };
    },
    mounted () {
        this.model = this.value;
        const editor = this.$refs.editor;
        const options = {
            element: editor,
            hideIcons: true,
            lineNumbers: true,
            spellChecker: true,
            toolbar: false,
            status: false,

            ...this.config
        };

        const easyMDE = new EasyMDE(options);
        easyMDE.value(this.value);

        easyMDE.codemirror.on('change', () => {
            const value = easyMDE.value();
            this.model = value;
            this.$emit('input', value);
        });

        this.easyMDE = easyMDE;
    },

    methods: {
        clear () {
            this.model = '';
            this.$emit('input', '');
        }
    }
};
</script>

<style>
.EasyMDEContainer .CodeMirror {
    padding: 0;
}
</style>
