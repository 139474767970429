<template>
    <div class="full-height fl-y">
        <top-bar description="Chatbots">
            <div class="w-25r mr-3">
                <validated-vue-select :options="bots"/>
            </div>
        </top-bar>

        <chat-box-layout>
            <template #left-box>
                <div class="fl-gr-1"></div>
                <div>
                    <btn color="success" text="Start new Chat" block />
                </div>
            </template>

            <template #top-box>
                <chat-bubble is-user message="User message" />
                <chat-bubble message="Bot message" />
            </template>

            <template #bottom-box>
                <validated-text-area class="bg-2" textarea-classes="min-h-8r" />
            </template>
        </chat-box-layout>
    </div>
</template>

<script>
import axios from 'secure-axios';
import TopBar from '@components/TopBar.vue';
import ChatBubble from '@components/ChatBubble.vue';
import ChatBoxLayout from '@components/layouts/ChatBoxLayout.vue';
import urls from '../../data/urls';

export default {
    name: 'ChatBot',
    components: { ChatBoxLayout, ChatBubble, TopBar },
    data () {
        return {
            bots: []
        };
    },

    async mounted () {
        try {
            const response = await axios.get(urls.bots.listChatBots);
            this.bots = response.data.response;
            console.log(this.bots);
        } catch (error) {
            console.error(error);
        }
    },

    methods: {}
};
</script>
