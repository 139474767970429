<template>
    <div class="fl-y full-height">
        <top-bar description="Projects">
        </top-bar>
        <div class="fl-gr-1">
            <div class="container">
                <div v-if="loading" class="card bg-info">
                    <loading-animation/>
                    <span class="ml-2">Loading...</span>
                </div>

                <div class="row">
                    <div v-for="project in projects" :key="project.id" class="col-md-4">
                        <div class="mt-3 px-3 py-2 bg-info min-h-6r round-1" @click="loadProject(project)">
                            <small v-text="project.client"></small>
                            <br>
                            <b v-text="project.name"></b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TopBar from '@components/TopBar.vue';
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'ProjectList',
    components: {
        TopBar
    },

    data () {
        return {
            loading: false,
            projects: []
        };
    },

    mounted () {
        this.getProjects();
    },

    methods: {
        async getProjects () {
            this.loading = true;
            try {
                const response = await axios.get(urls.projects.list);
                this.projects = response.data.projects;
            } catch (error) {
                console.error(error);
            }
            this.loading = false;
        },

        loadProject (project) {
            this.$router.push({ name: 'ProjectDetails', params: { id: project.id } });
        }
    }
};
</script>
