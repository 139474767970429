<template>
    <editor name="Tonality" :save-url="urls.updateTonality" :list-url="urls.listTonality" :new-url="urls.createTonality"/>
</template>
<script>
import Editor from '@/views/editor/Editor.vue';
import urls from '@/data/urls';

export default {
    components: { Editor },
    data () { return { urls: urls.master }; }
};
</script>
